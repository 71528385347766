import { Component, Input, OnDestroy } from '@angular/core';
import { GridMeta } from "../../shared/interfaces/grid-meta";
import { ROW_TO_NUMBER } from "../../shared/constants";
import { colorArrayToRgb } from "../../shared/utils/color-array-to-rgb";
import { Subject, takeUntil, timer } from "rxjs";

@Component({
  selector: 'cc-crowd-preview',
  templateUrl: './crowd-preview.component.html',
  styleUrls: ['./crowd-preview.component.scss']
})
export class CrowdPreviewComponent implements OnDestroy {
  gridMeta: GridMeta = { seats: {}, rows: 0, columns: 0 };
  gridState: { connected: boolean, colors: string[] }[][] = [];
  rowsMarks: string[] = [];
  seatsMarks: number[] = [];
  resetTimer$ = new Subject<void>();
  currentColorIndex: number = 0;
  colorsCount: number = 1;

  @Input() set grid(grid: GridMeta) {
    this.gridMeta = grid;
    this.gridState = new Array(this.gridMeta.rows)
    this.rowsMarks = Object.keys(ROW_TO_NUMBER).slice(0, this.gridMeta.rows);
    this.seatsMarks = Object.values(ROW_TO_NUMBER).slice(0, this.gridMeta.columns);

    for (let i = 0; i < this.gridMeta.rows; i++) {
      this.gridState[i] = new Array(this.gridMeta.columns).fill({ connected: false })
    }

    Object.keys(this.gridMeta.seats).forEach(seatId => {
      const [row, column] = seatId.split('.');
      this.gridState[Number(row) - 1][Number(column) - 1] = {
        connected: this.gridMeta.seats[seatId].connected,
        colors: this.gridMeta.seats[seatId].colors.map(color => colorArrayToRgb(color))
      }
    })

    this.currentColorIndex = 0;
    this.resetTimer$.next();
    this.colorsCount = this.gridMeta?.seats['1.1']?.colors.length || 0;

    if (this.colorsCount > 1) {
      timer(1000, 1000).pipe(takeUntil(this.resetTimer$)).subscribe(() => {
        this.currentColorIndex = (this.currentColorIndex + 1) % this.colorsCount;
      })
    }
  }

  ngOnDestroy(): void {
    this.resetTimer$.next();
  }
}
