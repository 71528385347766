import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrowdRoutingModule } from './crowd-routing.module';
import { CrowdPixelComponent } from './crowd-pixel/crowd-pixel.component';
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { WelcomeComponent } from './wolcome/welcome.component';


@NgModule({
  declarations: [
    CrowdPixelComponent,
    WelcomeComponent
  ],
  imports: [
    CommonModule,
    CrowdRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class CrowdModule { }
