import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'cc-set-color-dialog',
  templateUrl: './set-color-dialog.component.html',
  styleUrls: ['./set-color-dialog.component.scss']
})
export class SetColorDialogComponent {
  color: any;

  constructor(public dialogRef: MatDialogRef<SetColorDialogComponent>) { }

  apply(): void {
    const colorArray = this.color
      .replace(')', '')
      .replace('rgb(', '')
      .split(',')
      .map((c: string) => Number(c))

    this.dialogRef.close(colorArray);
  }
}
