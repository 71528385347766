<div class="wrapper">
  <div class="sun">
    <div class="circle"></div>
    <div class="ray r-1"><div class="r"></div></div>
    <div class="ray r-2"><div class="r"></div></div>
    <div class="ray r-3"><div class="r"></div></div>
    <div class="ray r-4"><div class="r"></div></div>
    <div class="ray r-5"><div class="r"></div></div>
    <div class="ray r-6"><div class="r"></div></div>
    <div class="ray r-7"><div class="r"></div></div>
    <div class="ray r-8"><div class="r"></div></div>
  </div>
  <div class="bar">
    <div class="line"></div>
    <div class="circle"></div>
  </div>

  <p class="text">Please set your screen<br>brightness to the max</p>
</div>
