<div class="wrapper">
  <div class="title">
    <span class="icon material-symbols-outlined">palette</span>
    <p>Set Color</p>
  </div>

  <input
    [(colorPicker)]="color"
    [cpOutputFormat]="'rgba'"
    [cpAlphaChannel]="'disabled'"
    [cpDialogDisplay]="'inline'"
    [cpPosition]="'bottom'"
    [cpWidth]="'300px'"
    [style.background]="color"/>

  <div class="actions">
    <button (click)="dialogRef.close()">Cancel</button>
    <button class="primary" (click)="apply()">Set</button>
  </div>
</div>
