<div class="wrapper">
  <div class="boxes">
    <div class="box d-3"></div>
    <div class="box d-2"></div>
    <div class="box d-1"></div>
    <div class="box d-0"></div>
    <div class="box d-0"></div>
    <div class="box d-1"></div>
    <div class="box d-2"></div>
    <div class="box d-3"></div>
  </div>
</div>
