import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Frame, Kernel, Room } from "../interfaces/room";

interface CreateResponse {
  success: boolean;
  message: string;
  id?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  constructor(private http: HttpClient) { }

  listRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(`/api/rooms-list`);
  }

  getRoomDetails(roomIdentifier: string): Observable<Room> {
    return this.http.get<Room>(`/api/rooms/${roomIdentifier}`);
  }

  createRoom(title: string, expected_rows: number, expected_columns: number): Observable<CreateResponse> {
    return this.http.post<CreateResponse>('/api/rooms/create', { title, expected_rows, expected_columns })
  }

  updateRoom(room: Room, title: string, expected_rows: number, expected_columns: number): Observable<Room> {
    return this.http.put<Room>(`/api/rooms/update/${room._id}`, { title, expected_rows, expected_columns })
  }

  addRoomFrame(room: Room, data: FormData): Observable<Room> {
    return this.http.post<Room>(`/api/room/add-frame/${room.name_identifier}`, data)
  }

  updateRoomFrame(room: Room, frame: Frame, data: FormData): Observable<Room> {
    data.append('frame_id', frame._id);
    return this.http.put<Room>(`/api/room/update-frame/${room.name_identifier}`, data)
  }

  deleteRoomFrame(room: Room, frame: Frame): Observable<Room> {
    return this.http.post<Room>(`/api/room/delete-frame/${room.name_identifier}`, frame)
  }

  getFramePreview(url: string, height: number, width: number, kernel: Kernel): Observable<Record<string, number[]>> {
    return this.http.post<Record<string, number[]>>(`/api/frame-preview`, { image_url: url, height, width, kernel })
  }

  getFrameFilePreview(data: FormData): Observable<Record<string, number[]>> {
    return this.http.post<Record<string, number[]>>(`/api/frame-preview-file`, data)
  }
}
