import { Component, Input } from '@angular/core';
import { colorArrayToRgb } from "../../shared/utils/color-array-to-rgb";
import { fadeInOutAnimation } from "../../shared/animations/fadeInOut";

@Component({
  selector: 'cc-frame-preview',
  templateUrl: './frame-preview.component.html',
  styleUrls: ['./frame-preview.component.scss'],
  animations: [fadeInOutAnimation]
})
export class FramePreviewComponent {
  gridState: { color: string }[][] = [];

  @Input() rows?: number;
  @Input() columns?: number;
  @Input() set pixels(pixelsData: Record<string, number[]>) {
    if (!this.rows || !this.columns) {
      return;
    }

    for (let i = 0; i < this.rows; i++) {
      this.gridState[i] = new Array(this.columns).fill('black')
    }

    Object.keys(pixelsData).forEach(seatId => {
      const [row, column] = seatId.split('.');
      this.gridState[Number(row) - 1][Number(column) - 1] = {
        color: colorArrayToRgb(pixelsData[seatId])
      }
    })
  }
}
