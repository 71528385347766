import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angular2-qrcode';
import { MatDialogModule } from '@angular/material/dialog';

import { ControlPanelRoutingModule } from './control-panel-routing.module';
import { RoomControlPanelComponent } from './room-control-panel/room-control-panel.component';
import { RoomQrComponent } from './room-qr/room-qr.component';
import { ControlMainComponent } from './control-main/control-main.component';
import { CreateRoomDialogComponent } from './create-room-dialog/create-room-dialog.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { CreateFrameDialogComponent } from './create-frame-dialog/create-frame-dialog.component';
import { FrameComponent } from './frame/frame.component';
import { FramePreviewComponent } from './frame-preview/frame-preview.component';
import { CrowdPreviewComponent } from './crowd-preview/crowd-preview.component';
import { SetColorDialogComponent } from './set-color-dialog/set-color-dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';


@NgModule({
  declarations: [
    RoomControlPanelComponent,
    RoomQrComponent,
    ControlMainComponent,
    CreateRoomDialogComponent,
    CreateFrameDialogComponent,
    FrameComponent,
    FramePreviewComponent,
    CrowdPreviewComponent,
    SetColorDialogComponent
  ],
  imports: [
    CommonModule,
    ControlPanelRoutingModule,
    QRCodeModule,
    MatDialogModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    ColorPickerModule
  ]
})
export class ControlPanelModule { }
