<div class="wrapper">
  <header class="control-header">
    <div class="title">
      <p class="logo"><img class="dots-icon" src="static/assets/dots.svg">Crowd Control</p>
    </div>

    <div class="loading" *ngIf="loading">
      <app-loading></app-loading>
    </div>

    <div class="actions">
      <button class="action" (click)="createRoom()">
        <span class="icon material-symbols-outlined">room_preferences</span>
        <span>Create Room</span>
      </button>
    </div>
  </header>

  <main class="control-main">
    <div class="rooms-list">
      <a *ngFor="let room of rooms" [routerLink]="['/control', room.name_identifier]">
        <div class="room">
          <p class="title">
            <span class="icon material-symbols-outlined">meeting_room</span>
            <span>{{ room.title }}</span>
          </p>
          <p>Expected Rows: {{ room.rows_expected }}</p>
          <p>Expected Columns: {{ room.columns_expected }}</p>
        </div>
      </a>
    </div>
  </main>
</div>
