import { Component, OnInit } from '@angular/core';
import { SocketService } from "../../socket/socket.service";
import { GridMeta } from "../../shared/interfaces/grid-meta";
import { mergeWith, switchMap } from "rxjs";
import { RoomsService } from "../../shared/services/rooms.service";
import { ActivatedRoute } from "@angular/router";
import { Frame, Room } from "../../shared/interfaces/room";
import { MatDialog } from "@angular/material/dialog";
import { CreateFrameDialogComponent } from "../create-frame-dialog/create-frame-dialog.component";
import { CreateRoomDialogComponent } from "../create-room-dialog/create-room-dialog.component";
import { SetColorDialogComponent } from "../set-color-dialog/set-color-dialog.component";

@Component({
  selector: 'app-room-control-panel',
  templateUrl: './room-control-panel.component.html',
  styleUrls: ['./room-control-panel.component.scss']
})
export class RoomControlPanelComponent implements OnInit {
  loading = false;
  roomId: string = 'prime';
  room?: Room;
  activeFrames: Frame[] = [];
  sequenceSelection: string[] = [];
  gridMeta: GridMeta = { seats: {}, rows: 0, columns: 0 };

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    private roomsService: RoomsService,
    private dialogs: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.pipe(switchMap(params => {
      this.roomId = params['room_id'];
      return this.roomsService.getRoomDetails(this.roomId);
    })).subscribe(room => {
      this.setRoom(room);
      this.loading = false;
      this.socketService.joinRoomControl(this.roomId);
    })

    this.socketService.gridResize()
      .pipe(mergeWith(this.socketService.frameUpdate()))
      .subscribe((data: GridMeta) => this.gridMeta = data);
  }

  setRoom(roomUpdate: Room): void {
    this.room = roomUpdate;
  }

  setFrameColor(color: number[]): void {
    this.activeFrames = [];
    this.socketService.setFrameColor(this.roomId, color);
  }

  endShow(): void {
    this.activeFrames = [];
    this.socketService.endShow(this.roomId);
  }

  setFrame(frame: Frame): void {
    this.activeFrames = [frame];
    this.sequenceSelection = [];
    this.socketService.setSequence(this.roomId, this.activeFrames);
  }

  setSequence(): void {
    if (!this.room?.frames || !this.sequenceSelection ) {
      return;
    }

    this.activeFrames = this.room?.frames.filter(frame => this.sequenceSelection.includes(frame._id));
    this.sequenceSelection = [];
    this.socketService.setSequence(this.roomId, this.activeFrames);
  }

  toggleFrameSequenceSelection(frame: Frame): void {
    if (this.sequenceSelection.includes(frame._id)) {
      const index = this.sequenceSelection.indexOf(frame._id, 0);
      this.sequenceSelection.splice(index, 1);
    } else {
      this.sequenceSelection.push(frame._id);
    }
  }

  copyRoomLink(): void {
    navigator.clipboard.writeText(`${location.origin}/room/${this.roomId}`);
  }

  addFrame(): void {
    this.dialogs.open(
      CreateFrameDialogComponent,
      {
        data: {
          room: this.room,
        },
        panelClass: 'dialog-container',
        position: { top: '80px' },
        backdropClass: 'black-backdrop',
      }).afterClosed().subscribe((updatedRoom?: Room) => {
        if (updatedRoom) {
          this.setRoom(updatedRoom);
        }
    })
  }

  openColorPicker(): void {
    this.dialogs.open(
      SetColorDialogComponent,
      {
        data: {
          room: this.room,
        },
        panelClass: 'dialog-container',
        position: { top: '80px' },
        backdropClass: 'black-backdrop',
      }).afterClosed().subscribe((color?: number[]) => {
        if (color?.length) {
          this.setFrameColor(color);
        }
    })
  }

  editRoom(): void {
    this.dialogs.open(
      CreateRoomDialogComponent,
      {
        data: {
          room: this.room,
        },
        panelClass: 'dialog-container',
        position: { top: '80px' },
        backdropClass: 'black-backdrop',
      }).afterClosed().subscribe((updatedRoom?: Room) => {
        if (updatedRoom) {
          this.setRoom(updatedRoom);

          if (this.room) {
            this.socketService.sendRoomUpdated(this.roomId, this.room.rows_expected, this.room.columns_expected);
          }
        }
    });
  }
}

