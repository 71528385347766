<div class="wrapper">
  <div class="title">
    <span class="icon material-symbols-outlined">room_preferences</span>
    <p>{{editMode ? 'Update' : 'Create'}} Room</p>
  </div>

  <form class="room-form" [formGroup]="formGroup">
    <label for="room-title">Room Title</label>
    <input id="room-title" placeholder="Title" type="text" formControlName="title">

    <div class="expected-size">
      <div class="size-input">
        <label for="room-expected_rows">Expected Rows</label>
        <input id="room-expected_rows" type="number" formControlName="expected_rows">
      </div>

      <div class="size-input">
        <label for="room-expected_columns">Expected Columns</label>
        <input id="room-expected_columns" type="number" formControlName="expected_columns">
      </div>
    </div>
  </form>

  <div class="actions">
    <p class="error" *ngIf="error">{{error}}</p>
    <app-loading *ngIf="loading"></app-loading>
    <button [disabled]="loading" (click)="dialogRef.close()">Cancel</button>
    <button class="primary" [disabled]="formGroup.invalid || loading" (click)="createRoom()">{{editMode ? 'Update' : 'Create'}}</button>
  </div>
</div>


