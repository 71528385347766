import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoomControlPanelComponent } from "./room-control-panel/room-control-panel.component";
import { RoomQrComponent } from "./room-qr/room-qr.component";
import { ControlMainComponent } from "./control-main/control-main.component";

const routes: Routes = [
  { path: 'control', component: ControlMainComponent },
  { path: 'control/:room_id', component: RoomControlPanelComponent },
  { path: 'room-qr/:room_id', component: RoomQrComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ControlPanelRoutingModule { }
