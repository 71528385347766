import {Component, Inject, ViewChild} from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Room, Frame } from "../../shared/interfaces/room";
import { RoomsService } from "../../shared/services/rooms.service";
import { debounceTime } from "rxjs";

interface CreateFrameInput {
  room: Room,
  frame?: Frame,
}

type Kernel = 'soft' | 'sharp';

@Component({
  selector: 'cc-create-frame-dialog',
  templateUrl: './create-frame-dialog.component.html',
  styleUrls: ['./create-frame-dialog.component.scss']
})
export class CreateFrameDialogComponent {
  room: Room;
  frame?: Frame;
  loading = false;
  error?: string;
  editMode: boolean = false;
  preview?: Record<string, number[]>;
  imagePreview?: string;
  image?: File;
  kernel: Kernel = 'sharp';

  formGroup = this.formBuilder.group({
    title: ['', [Validators.required]],
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateFrameInput,
    public dialogRef: MatDialogRef<CreateFrameDialogComponent>,
    private formBuilder: FormBuilder,
    private roomsService: RoomsService,
  ) {
    this.formGroup.valueChanges.pipe(debounceTime(300)).subscribe(() => {});

    this.room = data.room;
    if (data.frame) {
      this.editMode = true;
      this.frame = data.frame;
      this.formGroup.setValue({ title: data.frame.title || null })
      this.imagePreview = this.frame.image_url;
      this.kernel = data.frame.kernel;
    }
  }

  onImageError(): void {
    this.imagePreview = undefined;
  }

  onImageLoad(): void {
    if (this.imagePreview) {
      if (this.imagePreview === this.frame?.image_url) {
        this.loading = true;
        this.roomsService.getFramePreview(this.imagePreview, this.room.rows_expected, this.room.columns_expected, this.kernel).subscribe(preview => {
          this.preview = preview;
          this.loading = false;
        })
      } else {
        if (this.image) {
          this.loading = true;
          const postData = new FormData();
          postData.append('height', this.room.rows_expected + '');
          postData.append('width', this.room.columns_expected + '');
          postData.append('kernel', this.kernel);
          postData.append('image', this.image, 'frame-preview');
          this.roomsService.getFrameFilePreview(postData).subscribe(preview => {
            this.preview = preview;
            this.loading = false;
          });
        }
      }
    }
  }

  onImagePick(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (!files) {
      return
    }

    this.image = files[0];

    const reader = new FileReader();
    reader.onload = () => {
     this.imagePreview = reader.result as string;
    }
    reader.readAsDataURL(this.image);
  }

  toggleKernel(): void {
    if (this.kernel === 'soft') {
      this.kernel = 'sharp';
    } else {
      this.kernel = 'soft';
    }

    this.onImageLoad();
  }

  apply(): void {
    if (this.editMode) {
      this.updateFrame();
    } else {
      this.createFrame();
    }
  }

  updateFrame(): void {
    this.error = undefined;

    if (this.formGroup.invalid || !this.frame) {
      return;
    }

    const title = this.formGroup.get('title')?.value || `room-${this.room.title}-frame.png`;
    this.loading = true;

    const postData = new FormData();
    postData.append('title', title);
    postData.append('kernel', this.kernel);

    if (this.image) {
      postData.append('image', this.image, title);
      postData.append('delete_file_name', this.frame.file_name);
    }

    this.loading = true;
    this.roomsService.updateRoomFrame(this.room, this.frame, postData).subscribe((response) => {
      this.loading = false;
      this.dialogRef.close(response);
    });
  }

  createFrame(): void {
    this.error = undefined;

    if (this.formGroup.invalid || !this.image) {
      return;
    }

    const title = this.formGroup.get('title')?.value || `room-${this.room.title}-frame.png`;
    this.loading = true;

    const postData = new FormData();
    postData.append('title', title);
    postData.append('kernel', this.kernel);
    postData.append('image', this.image, title);

    this.roomsService.addRoomFrame(this.room, postData).subscribe((response) => {
      this.loading = false;
      this.dialogRef.close(response);
    });
  }

  deleteFrame(): void {
    if (!this.frame) {
      return;
    }

    this.loading = true;
    this.roomsService.deleteRoomFrame(this.room, this.frame).subscribe((response) => {
      this.loading = false;
      this.dialogRef.close(response);
    });
  }
}
