import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { HttpClientModule } from "@angular/common/http";
import { KeepAwakeDirective } from './keep-awake/keep-awake.directive';
import { DotsIconComponent } from './dots-icon/dots-icon.component';
import { BrightnessAnimationComponent } from './brightness-animation/brightness-animation.component';



@NgModule({
  declarations: [
    LoadingComponent,
    KeepAwakeDirective,
    DotsIconComponent,
    BrightnessAnimationComponent
  ],
    exports: [
        LoadingComponent,
        KeepAwakeDirective,
        DotsIconComponent,
        BrightnessAnimationComponent
    ],
  imports: [
    CommonModule,
    HttpClientModule,
  ]
})
export class SharedModule { }
