import { Directive, ElementRef, OnDestroy } from '@angular/core';
import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();

@Directive({
  selector: '[ccKeepAwake]'
})
export class KeepAwakeDirective implements OnDestroy {

  constructor(el: ElementRef) {
    el.nativeElement.addEventListener('click', function enableNoSleep() {
      el.nativeElement.removeEventListener('click', enableNoSleep, false);
      noSleep.enable();
    }, false);
  }

  ngOnDestroy() {
    noSleep.disable();
  }
}
