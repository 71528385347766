import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-room-qr',
  templateUrl: './room-qr.component.html',
  styleUrls: ['./room-qr.component.scss']
})
export class RoomQrComponent implements OnInit {
  roomId?: string;
  link?: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.roomId = params['room_id'];
      this.link = `${location.origin}/room/${this.roomId}`;
    });
  }
}
