<div class="wrapper">
  <div class="row">
    <div class="dot d-11"></div>
    <div class="dot d-1"></div>
    <div class="dot d-6"></div>
  </div>

  <div class="row">
    <div class="dot d-7"></div>
    <div class="dot d-10"></div>
    <div class="dot d-2"></div>
  </div>

  <div class="row">
    <div class="dot d-9"></div>
    <div class="dot d-3"></div>
    <div class="dot d-5"></div>
  </div>

  <div class="row">
    <div class="dot d-4"></div>
    <div class="dot d-8"></div>
    <div class="dot d-12"></div>
  </div>
</div>
