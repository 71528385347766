import { Injectable } from '@angular/core';
import { io, Socket } from "socket.io-client";
import { Observable } from "rxjs";
import { GridMeta } from "../shared/interfaces/grid-meta";
import { Frame } from "../shared/interfaces/room";

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket

  constructor() {
    this.socket = io('/', { reconnection: true, reconnectionAttempts: 8, reconnectionDelay: 1500 })
  }

  joinRoomControl(roomId: string): void {
    this.socket.emit('control', { roomId })
  }

  joinRoom(roomId: string, row: number, column: number): void {
    this.socket.emit('join', { roomId, row, column })
  }

  sendRoomUpdated(roomId: string, rows: number, columns: number): void {
    this.socket.emit('room-updated',{ roomId, rows, columns })
  }

  gridResize(): Observable<GridMeta> {
    return new Observable<GridMeta>((observer) => {
      this.socket.on('grid-resize', (data: GridMeta) => {
        observer.next(data)
      });
    })
  }

  onDisconnect(): Observable<Socket.DisconnectReason> {
    return new Observable<Socket.DisconnectReason>((observer) => {
      this.socket.on('disconnect', (err) => {
        observer.next(err)
      });
    })
  }

  setFrameColor(roomId: string, color: number[]): void {
    this.socket.emit('set-frame-color', { roomId, color })
  }

  endShow(roomId: string): void {
    this.socket.emit('end-show', { roomId })
  }

  setSequence(roomId: string, frames: Frame[]): void {
    this.socket.emit('set-frame-sequence', { roomId, frames })
  }

  frameUpdate(): Observable<GridMeta> {
    return new Observable<GridMeta>((observer) => {
      this.socket.on('frame-updated', (data: GridMeta) => {
        observer.next(data)
      });
    })
  }

  showEnded(): Observable<GridMeta> {
    return new Observable<GridMeta>((observer) => {
      this.socket.on('show-ended', (data: GridMeta) => {
        observer.next(data)
      });
    })
  }
}
