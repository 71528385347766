import { Component, OnDestroy, OnInit } from '@angular/core';
import { SocketService } from "../../socket/socket.service";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { RoomsService } from "../../shared/services/rooms.service";
import { mergeWith, of, Subject, switchMap, take, takeUntil, timer } from "rxjs";
import { Room } from "../../shared/interfaces/room";
import { fadeInOutAnimation, fadeInOutMax03Animation } from "../../shared/animations/fadeInOut";
import { ROW_TO_NUMBER } from "../../shared/constants";
import { colorArrayToRgb } from "../../shared/utils/color-array-to-rgb";

@Component({
  selector: 'app-crowd-pixel',
  templateUrl: './crowd-pixel.component.html',
  styleUrls: ['./crowd-pixel.component.scss'],
  animations: [fadeInOutAnimation, fadeInOutMax03Animation]
})
export class CrowdPixelComponent implements OnInit, OnDestroy {
  roomId?: string;
  row?: number;
  rowLetter?: string;
  column?: number;
  colors: string[] = [];
  currentColor?: string;
  currentColorIndex: number = 0;
  seatId?: string;
  loading = false;
  room?: Room;
  rowsOptions: string[] = [];
  seatsOptions: number[] = [];
  connected = false;
  hideControls = false;
  showBrightnessMessage = false;
  forceControls = true;
  showEnded = false;
  showEnded$ = new Subject<boolean>();
  resetTimer$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    row: ['A', [Validators.required]],
    column: [1, [Validators.required]]
  })

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    private formBuilder: FormBuilder,
    private roomsService: RoomsService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.roomId = params['room_id']);

    this.loading = true;
    this.route.params.pipe(switchMap(params => {
      this.roomId = params['room_id'];
      if (!this.roomId) {
        return of(undefined)
      }
      return this.roomsService.getRoomDetails(this.roomId);
    })).subscribe(room => {
      this.room = room;
      this.loading = false;

      if (this.room) {
        this.rowsOptions = Object.keys(ROW_TO_NUMBER).slice(0, this.room.rows_expected);
        this.seatsOptions = Object.values(ROW_TO_NUMBER).slice(0, this.room.columns_expected);
      }
    });

    this.socketService.frameUpdate()
      .pipe(mergeWith(this.socketService.gridResize()), takeUntil(this.showEnded$)).subscribe((data => {
      if (!this.seatId) {
        return;
      }

      this.connected = data.seats[this.seatId].connected;
      this.hideControls = !this.forceControls && this.connected;
      this.colors = data.seats[this.seatId].colors.map(color => colorArrayToRgb(color));

      this.resetTimer$.next();
      const syncTime = Math.max(0, (data.next_frame_time || 0) - Date.now());

      timer(syncTime).pipe(takeUntil(this.resetTimer$), take(1)).subscribe(() => {
        this.setColorByIndex(0);

        if (this.colors.length > 1) {
          timer(1000, 1000).pipe(takeUntil(this.resetTimer$)).subscribe(() => {
            this.setColorByIndex(this.currentColorIndex + 1);
          })
        }
      })

      if (!this.showBrightnessMessage) {
        this.showBrightnessMessage = true;
      }
    }));

    this.socketService.showEnded().subscribe(data => {
      this.hideControls = true;
      this.showEnded = true;
      this.showEnded$.next(true);
      this.resetTimer$.next();
    })
  }

  private setColorByIndex(index: number): void {
    this.currentColorIndex = index % this.colors.length;
    this.currentColor = this.colors[this.currentColorIndex];
  }

  submitJoinForm(): void {
    if (this.formGroup.invalid) {
      return
    }

    this.rowLetter = this.formGroup.get('row')?.value || undefined;

    if (!this.rowLetter || !Object.keys(ROW_TO_NUMBER).includes(this.rowLetter)) {
      return;
    }

    this.row = ROW_TO_NUMBER[this.rowLetter];
    this.column = Number(this.formGroup.get('column')?.value as number);
    this.joinRoom()
  }

  joinRoom(): void {
    if (this.hideControls || !this.roomId || this.row === undefined || this.column === undefined) {
      return
    }

    this.socketService.onDisconnect().subscribe((err) => {
      this.connected = false;
      this.resetTimer$.next();
      this.showControls();
    })

    this.forceControls = false;
    this.socketService.joinRoom(this.roomId, this.row, this.column)
    this.seatId = `${this.row}.${this.column}`;
  }

  showControls(): void {
    this.forceControls = true;
    this.hideControls = false;
  }

  ngOnDestroy(): void {
    this.showEnded$.next(true);
    this.resetTimer$.next();
  }
}
