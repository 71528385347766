<div class="wrapper">
  <div class="title">
    <span class="icon material-symbols-outlined">add_photo_alternate</span>
    <p>{{ editMode ? 'Edit' : 'Add' }} Frame</p>
  </div>

  <form class="room-form" [formGroup]="formGroup">
    <div class="title-input">
      <label for="frame-title">Title</label>
      <input id="frame-title" placeholder="Title" type="text" formControlName="title">
    </div>

    <div class="kernel-input">
      <button type="button" class="kernel-toggle" (click)="toggleKernel()"><span class="material-symbols-outlined icon">deblur</span><span>Kernel: <span>{{ kernel | titlecase }}</span></span></button>
    </div>

    <div class="image-input">
      <button type="button" class="upload-image" (click)="filePicker.click()"><span class="material-symbols-outlined icon">image</span><span>{{ editMode ? 'Change' : 'Upload' }} Image</span></button>
      <input type="file" #filePicker (change)="onImagePick($event)">
    </div>
  </form>

  <div class="preview">
    <div class="image-preview" *ngIf="imagePreview">
      <img [src]="imagePreview" (error)="onImageError()" (load)="onImageLoad()">
    </div>

    <div *ngIf="preview && room" class="pixels-preview">
      <cc-frame-preview [rows]="room.rows_expected" [columns]="room.columns_expected" [pixels]="preview"></cc-frame-preview>
    </div>
  </div>


  <div class="actions">
    <p class="error" *ngIf="error">{{error}}</p>
    <app-loading *ngIf="loading"></app-loading>
    <button [disabled]="loading" (click)="dialogRef.close()">Cancel</button>
    <button *ngIf="editMode" class="danger" [disabled]="loading" (click)="deleteFrame()">Delete Frame</button>
    <button class="primary" [disabled]="formGroup.invalid || loading" (click)="apply()">{{ editMode ? 'Save' : 'Add' }}</button>
  </div>
</div>


