<div *ngIf="link" class="wrapper">
  <div class="qr">
    <qr-code
      [size]="400"
      [level]="'M'"
      [value]="link"
      [background]="'#b2aec5'"
      [foreground]="'#131118'"
    ></qr-code>
  </div>

  <p class="instructions">
    <span class="icon material-symbols-outlined">qr_code_scanner</span>
    <span>Scan to join the Room</span>
  </p>
</div>
