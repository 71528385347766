import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CrowdPixelComponent} from "./crowd-pixel/crowd-pixel.component";

const routes: Routes = [
  { path: 'room/:room_id', component: CrowdPixelComponent },
  { path: 'room', redirectTo: 'room/prime', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrowdRoutingModule { }
