import { Component, OnInit } from '@angular/core';
import { RoomsService } from "../../shared/services/rooms.service";
import { Room } from "../../shared/interfaces/room";
import { MatDialog } from "@angular/material/dialog";
import { CreateRoomDialogComponent } from "../create-room-dialog/create-room-dialog.component";

@Component({
  selector: 'cc-control-main',
  templateUrl: './control-main.component.html',
  styleUrls: ['./control-main.component.scss']
})
export class ControlMainComponent implements OnInit {
  loading = false;
  rooms: Room[] = [];

  constructor(private roomsService: RoomsService, private dialogs: MatDialog) { }

  ngOnInit(): void {
    this.loading = true;
    this.roomsService.listRooms().subscribe(rooms => {
      this.loading = false;
      this.rooms = rooms;
    });
  }

  createRoom(): void {
    this.dialogs.open(
      CreateRoomDialogComponent,
      {
        panelClass: 'dialog-container',
        position: { top: '80px' },
        backdropClass: 'black-backdrop',
      })
  }
}
