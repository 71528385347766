import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { RoomsService } from "../../shared/services/rooms.service";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Room } from "../../shared/interfaces/room";

interface EditRoomInput {
  room?: Room
}

@Component({
  selector: 'cc-create-room-dialog',
  templateUrl: './create-room-dialog.component.html',
  styleUrls: ['./create-room-dialog.component.scss']
})
export class CreateRoomDialogComponent {
  loading = false;
  error?: string;
  editMode = false;

  formGroup = this.formBuilder.group({
    expected_rows: [4, [Validators.required, Validators.min(1)]],
    expected_columns: [5, [Validators.required, Validators.min(1)]],
    title: ['', [Validators.required]]
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditRoomInput,
    public dialogRef: MatDialogRef<CreateRoomDialogComponent>,
    private roomsService: RoomsService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    console.log('data', this.data)
    if (this.data && this.data.room) {
      this.editMode = true;
      this.formGroup.setValue({
        expected_rows: this.data.room.rows_expected,
        expected_columns: this.data.room.columns_expected,
        title: this.data.room.title
      })

      this.formGroup.get('title')?.disable();
    }
  }

  createRoom(): void {
    this.error = undefined;

    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;
    const title = this.formGroup.get('title')?.value || '';
    const expected_rows = Number(this.formGroup.get('expected_rows')?.value);
    const expected_columns = Number(this.formGroup.get('expected_columns')?.value);

    if (this.editMode && this.data.room) {
      this.roomsService.updateRoom(this.data.room, title, expected_rows, expected_columns).subscribe((response) => {
        this.loading = false;
        this.dialogRef.close(response)
      })
    } else {
      this.roomsService.createRoom(title, expected_rows, expected_columns).subscribe((response) => {
        this.loading = false;
        if (response.success) {
          this.router.navigate(['/control', response.id]);
          this.dialogRef.close();
        } else {
          this.error = response.message
        }
      });
    }
  }
}
