<div class="wrapper">
  <header class="control-header">
    <a class="title" [routerLink]="['/control']">
      <p class="logo"><img class="dots-icon" src="static/assets/dots.svg">Crowd Control</p>
      <p *ngIf="room">Room Control: <a target="_blank" [routerLink]="['/room', roomId]" class="room-name">{{ room.title }}</a></p>
    </a>

    <div class="loading" *ngIf="loading">
      <app-loading></app-loading>
    </div>

    <div class="actions">
      <button class="action" (click)="copyRoomLink()">
        <span class="icon material-symbols-outlined">offline_share</span>
        <span>Room Link</span>
      </button>
      <a target="_blank" [routerLink]="['/room-qr', roomId]">
      <button class="action">
        <span class="icon material-symbols-outlined">qr_code</span>
        <span>Room QR</span>
      </button>
      </a>
    </div>
  </header>

  <ng-container *ngIf="room else noRoom">
    <main>
      <section class="crowd-preview">
        <cc-crowd-preview [grid]="gridMeta"></cc-crowd-preview>
      </section>

      <section class="room-actions">
        <button class="end-show" (click)="endShow()">
          <span class="icon material-symbols-outlined">front_hand</span>
          <span>End</span>
        </button>

        <button (click)="setSequence()" class="sequence" [disabled]="sequenceSelection.length < 2">
          <span class="icon material-symbols-outlined">animation</span>
          <span>Sequence</span>
        </button>

        <button (click)="setFrameColor([0, 0, 0])">
          <span class="icon material-symbols-outlined">dark_mode</span>
          <span>Darken</span>
        </button>

        <button (click)="openColorPicker()">
          <span class="icon material-symbols-outlined">palette</span>
          <span>Color</span>
        </button>

        <button (click)="addFrame()">
          <span class="icon material-symbols-outlined">add_photo_alternate</span>
          <span>Add Frame</span>
        </button>

        <button (click)="editRoom()">
          <span class="icon material-symbols-outlined">room_preferences</span>
          <span>Edit Room</span>
        </button>
      </section>

      <section class="panel">
        <div class="frames-wrapper">
          <ng-container *ngIf="room.frames.length else noFrames">
            <div class="frames">
              <cc-frame
                *ngFor="let frame of room.frames; let i = index"
                [frame]="frame"
                [index]="i"
                [active]="activeFrames.includes(frame)"
                [sequenceSelected]="sequenceSelection.includes(frame._id)"
                [room]="room"
                (selected)="setFrame($event)"
                (sequenceSelectionToggle)="toggleFrameSequenceSelection($event)"
                (updatedRoom)="setRoom($event)"
              ></cc-frame>
            </div>
          </ng-container>

          <ng-template #noFrames>
            <p class="no-frames">This room doesn't have frames yet</p>
          </ng-template>
        </div>
      </section>
    </main>
  </ng-container>

  <ng-template #noRoom>
    <div class="no-room" *ngIf="!loading">
      <p>Could not find a room named '{{roomId}}'.</p>
    </div>
  </ng-template>
</div>


