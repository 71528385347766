<div class="frame" [@fadeInOutAnimation] *ngIf="_frame">
  <div class="preview-and-actions">
    <div class="actions-wrapper">
      <button (click)="onSelected()" [class.active]="active"><span class="material-symbols-outlined">vertical_align_top</span></button>
      <button (click)="onToggleSequenceSelect()" [class.sequence-selected]="sequenceSelected"><span class="material-symbols-outlined">{{ sequenceSelected ? 'check_box' : 'check_box_outline_blank' }}</span></button>
      <button (click)="editFrame()"><span class="material-symbols-outlined">tune</span></button>
    </div>

    <div class="preview-wrapper">
      <div class="title">
        <p><span>{{ _frame.title }}</span></p>
      </div>
      <div class="preview">
        <img [src]="_frame.image_url">
      </div>
      <div class="pixels-preview">
        <cc-frame-preview [pixels]="preview" [rows]="_room?.rows_expected" [columns]="_room?.columns_expected"></cc-frame-preview>
      </div>
    </div>
  </div>
</div>
