<div class="wrapper">
  <div *ngIf="seatId && currentColor" [@fadeInOutAnimation] class="pixel" [style.background-color]="currentColor"></div>
  <div *ngIf="seatId && !showEnded" [@fadeInOutMax03Animation] class="minimized-status" (click)="showControls()">
    <div class="row"><span>{{rowLetter}}</span></div>
    <div class="seat"><span>{{column}}</span></div>
    <span class="material-symbols-outlined icon">{{ connected ? 'all_inclusive' : 'link_off'}}</span>
    <span class="material-symbols-outlined icon settings">apps</span>
  </div>

  <div *ngIf="showEnded" [@fadeInOutAnimation] class="show-ended">
    <span class="material-symbols-outlined icon">waving_hand</span>
    <p class="line">Presentation Ended</p>
    <p>Thanks you! See you next time</p>
    <p class="subtitle">Neta Moses & Ben Ella</p>
  </div>

  <div class="loading" *ngIf="loading">
    <app-loading></app-loading>
  </div>

  <div class="brightness-animation" *ngIf="showBrightnessMessage">
    <cc-brightness-animation></cc-brightness-animation>
  </div>

  <ng-container *ngIf="room">
    <div class="join-setup" [class]="{ hidden: hideControls, connected }">
      <form [formGroup]="formGroup" (submit)="submitJoinForm()">
        <div class="selection">
          <div class="selector row-select">
            <p class="title">Row</p>
            <div class="select-wrap">
              <select formControlName="row">
                <option *ngFor="let option of rowsOptions">{{ option }}</option>
              </select>
            </div>
          </div>

          <div class="selector seat-select">
            <p class="title">Seat</p>
            <div class="select-wrap">
              <select formControlName="column">
                <option *ngFor="let option of seatsOptions">{{ option }}</option>
              </select>
            </div>
          </div>
        </div>

        <button class="join" type="submit" ccKeepAwake>
          <span class="material-symbols-outlined icon">all_inclusive</span>
          <span>Connect</span>
        </button>
      </form>
    </div>
  </ng-container>
</div>


