import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Frame, Room } from "../../shared/interfaces/room";
import { RoomsService } from "../../shared/services/rooms.service";
import { MatDialog } from "@angular/material/dialog";
import { CreateFrameDialogComponent } from "../create-frame-dialog/create-frame-dialog.component";
import { fadeInOutAnimation } from "../../shared/animations/fadeInOut";

@Component({
  selector: 'cc-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  animations: [fadeInOutAnimation]
})
export class FrameComponent {
  @Input() set frame(frame: Frame) {
    this._frame = frame;
    this.setFrame();
  };

  @Input() set room(room: Room) {
    this._room = room;
    this.setFrame();
  };

  @Input() index?: number;
  @Input() active: boolean = false;
  @Input() sequenceSelected: boolean = false;
  @Output() selected = new EventEmitter<Frame>();
  @Output() sequenceSelectionToggle = new EventEmitter<Frame>();
  @Output() updatedRoom = new EventEmitter<Room>();

  _frame?: Frame;
  _room?: Room;
  preview: Record<string, number[]> = {};

  constructor(private roomsService: RoomsService, private dialogs: MatDialog) { }

  setFrame(): void {
    if (!this._frame || !this._room) {
      return
    }

    this.roomsService.getFramePreview(this._frame.image_url, this._room.rows_expected, this._room.columns_expected, this._frame.kernel).subscribe(preview => {
      this.preview = preview;
    })
  }

  onSelected(): void {
    this.selected.emit(this._frame);
  }

  onToggleSequenceSelect(): void {
    this.sequenceSelectionToggle.emit(this._frame);
  }

  editFrame(): void {
    this.dialogs.open(
      CreateFrameDialogComponent,
      {
        data: {
          room: this._room,
          frame: this._frame
        },
        panelClass: 'dialog-container',
        position: { top: '80px' },
        backdropClass: 'black-backdrop',
      }).afterClosed().subscribe((updatedRoom?: Room) => {
        if (updatedRoom) {
          this.updatedRoom.emit(updatedRoom);
        }
    })
  }
}
