import { animate, style, transition, trigger } from "@angular/animations";

export const fadeInOutAnimation = trigger('fadeInOutAnimation',
  [
    transition(':enter', [style({ opacity: 0 }), animate('0.2s ease-out', style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate('0.2s ease-in', style({ opacity: 0 }))])
  ]
)

export const fadeInOutMax03Animation = trigger('fadeInOutMax03Animation',
  [
    transition(':enter', [style({ opacity: 0 }), animate('0.2s ease-out', style({ opacity: 0.3 }))]),
    transition(':leave', [style({ opacity: 0.3 }), animate('0.2s ease-in', style({ opacity: 0 }))])
  ]
)
