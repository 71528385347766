<section class="crowd">
  <div class="grid-wrapper">
    <div class="rows-marks"><div class="mark" *ngFor="let mark of seatsMarks"><span>{{mark}}</span></div></div>
    <div class="seats-marks"><div class="mark" *ngFor="let mark of rowsMarks"><span>{{mark}}</span></div></div>
    <div class="grid">
      <div class="row" *ngFor="let row of gridState">
        <div class="seat" [style.background-color]="seat.colors ? seat.colors[currentColorIndex] : 'black'" *ngFor="let seat of row">
          <div class="connection-state" [class.connected]="seat.connected"></div>
        </div>
      </div>
    </div>
  </div>
</section>
